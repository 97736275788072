<template>
    <div class="form-group">
        <label>{{label}}</label>
        <textarea
        type="text"
        class="form-control"
        style="height: 200px"
        @keyup="$emit('update:value', $event.target.value)"
        />
        <small class="text-danger">
        </small>
    </div>
</template>

<script>
export default {
    props: [
        'label'
    ]
}
</script>